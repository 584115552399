'use-strict';

/**
 * Add custom functionality for Amplience content types
 */

function getBannerVideo() {
    $('.js-video-banner-dialog').on('show.bs.modal', function (e) {
        var $this = $(this);
        var bannerSelector = $this.data('banner');
        var $banner = $(bannerSelector);
        var $btn = $(e.relatedTarget);

        if ($banner.length) {
            $('body').append($this); // Ensure modal isn't somewhere like a carousel that'll interfere with it by moving it
            var targetSelector = $btn.data('video');
            var $videoTemplate = $banner.find(targetSelector);

            if ($videoTemplate.length) {
                $this.html($videoTemplate.html());

                var $video = $this.find('video');

                if ($video.length) {
                    $video[0].play();
                }
            }
        }
    });
}

/**
 * Clears the content out of the video banner so the video doesn't keep playing and/or using resources
*/
function clearBannerVideo() {
    $('.js-video-banner-dialog').on('hidden.bs.modal', function () {
        $(this).html('');
    });
}

function videoBannerControls() {
    $('.js-video-banner-video').on('play pause', function () {
        const $videoBanner = $(this).closest('.c-video-banner__video-bg-wrap');
        $videoBanner.find('.js-video-banner-play.js-video-banner-toggler').toggleClass('d-none', !this.paused);
        $videoBanner.find('.js-video-banner-pause.js-video-banner-toggler').toggleClass('d-none', this.paused);
    });
    $('.js-video-banner-pause').on('click', function () {
        const $video = $(this).closest('.js-video-banner').find('.js-video-banner-video');
        if ($video.length) {
            const video = $video[0];
            video.pause();
        }
    });
    $('.js-video-banner-play').on('click', function () {
        const $video = $(this).closest('.js-video-banner').find('.js-video-banner-video');
        if ($video.length) {
            const video = $video[0];
            video.play();
        }
    });
    // Without controls being turned on, we need to allow clicking the video to play/pause it
    $('.js-video-banner-video').on('click', function () {
        const video = $(this)[0];
        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }
    });
}

/**
 * Pauses auto-playing video banner videos for "prefers-reduced-motion" users
 *
 * @param {MediaQueryList} matchMedia - Query for the reduced motion preference
 */
function applyReducedMotion(matchMedia) {
    if (matchMedia && matchMedia.matches) {
        $('.js-video-banner-video[autoplay]').each(function (ind, video) {
            video.pause();
        });
    }
}

function handleReducedMotion() {
    const baseModule = this;
    let applyReducedMotionScoped = applyReducedMotion;
    if (baseModule && baseModule.methods && baseModule.methods.applyReducedMotion) {
        applyReducedMotionScoped = baseModule.methods.applyReducedMotion;
    }

    const motionMatchMedia = window.matchMedia('(prefers-reduced-motion: reduce)');
    applyReducedMotionScoped(motionMatchMedia);
    if (motionMatchMedia.matches) {
        // Due to what appears to be a race condition, pausing on page load doesn't trigger event handler
        $('.js-video-banner-video[autoplay]').each(function (ind, video) {
            $(video).trigger('pause');
            const $videoBanner = $(this).closest('.js-video-banner');
            $videoBanner.find('.js-video-banner-play').removeClass('d-none');
            $videoBanner.find('.js-video-banner-pause').addClass('d-none');
        });
    }

    motionMatchMedia.addEventListener('change', function () {
        applyReducedMotionScoped(this);
    });
}

function handlePopups() {
    $('.hero-cta .btn').on('click', function(e){
        if ($(this).siblings('.hero-vid-popup').length > 0) {
            e.preventDefault();
            $(this).siblings('.hero-vid-popup').toggle();   
        }
    });
    $('.hero-vid-popup').on('click', function(){
        $(this).hide();
    })
}

module.exports = {
    methods: {
        applyReducedMotion: applyReducedMotion,
        clearBannerVideo: clearBannerVideo,
        getBannerVideo: getBannerVideo
    },
    getBannerVideo: getBannerVideo,
    clearBannerVideo: clearBannerVideo,
    handleReducedMotion: handleReducedMotion,
    videoBannerControls: videoBannerControls,
    handlePopups: handlePopups
};
